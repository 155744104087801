import { FC } from "react";
// import { Router } from "routes";
// import { reducer, ThoughtStateProvider } from "state";

export const App: FC = (): JSX.Element => {
  // const [thoughtState, dispatch] = useReducer(reducer, { thoughts: [] });

  /**
   *
   *  <ThoughtStateProvider
   *  value={{ thoughtState: thoughtState, dispatch: dispatch }}
   *  >
   *    <Router />
   *  </ThoughtStateProvider>
   */

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-100">
      <div className="text-xs">oops, something big is coming here 🛠</div>
    </div>
  );
};

export default App;
